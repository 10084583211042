export const experienceData = [
    {
        id: 1,
        company: 'Foundation AI',
        jobtitle: 'Software Engineer (Imple. Developer)',
        startYear: 'Feb 2024 ',
        endYear: ' Present'
    },
    {
        id: 2,
        company: 'Sujanix Private Limited',
        jobtitle: 'Software Engineer (Backend Developer)',
        startYear: 'Jun 2022 ',
        endYear: '  Feb 2024'
    },
    {
        id: 3,
        company: 'Qspider Noida',
        jobtitle: 'Internship cum Training on Python Full Stack Developer',
        startYear: 'May 2021 ',
        endYear: ' Mar 2022'
    },
    
]
